import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
// import Vconsole from 'vconsole'
// const vConsole1 = new Vconsole()
const app = createApp(App);
app.config.globalProperties.$http=axios
// app.use(vConsole1)
app.use(store).use(router).mount('#app')
